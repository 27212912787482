import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import {
  MetrostavBackgroundScreens,
  MetrostavImplement
} from "../../assets/images/Images";
import Button from "../../components/Button";
import { Caption, CaptionWrapper } from "../../components/Caption";
import Container from "../../components/Container";
import { Block, GridWrapper, Image } from "../../components/GridComponents";
import Spacer from "../../components/Spacer";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";
import { putEmptyLineInsteadSpace } from "../../utils/putEmptyLineInsteadSpace";
import HeadingBlock from "../general/HeadingBlock";
import { SIDEBAR_WIDTH } from "../SideBar";

const BackgroundBlock = styled.div`
  margin-left: ${rem(-SIDEBAR_WIDTH)};
  margin-right: ${rem(-SIDEBAR_WIDTH)};
`;

const ImplementSection: FC = () => {
  const intl = useIntl();

  return (
    <>
      <Container>
        <Spacer size="extraLarge" />
        <GridWrapper>
          <Block paddingRight={50} justifyContent="flex-start">
            <HeadingBlock
              heading={putEmptyLineInsteadSpace(
                intl.formatMessage({ id: "metrostav.implement_section.title" }),
                1
              )}
              pre={intl.formatMessage({
                id: "metrostav.implement_section.pre"
              })}
              paragraph={[
                intl.formatMessage({
                  id: "metrostav.implement_section.text_1"
                }),
                intl.formatMessage({
                  id: "metrostav.implement_section.text_2"
                })
              ]}
            />
            <Spacer size="medium" />
          </Block>
          <Block>
            <Image src={MetrostavImplement} alt="Metrostav" />
          </Block>
        </GridWrapper>
        <Spacer size="large" />
        <CaptionWrapper>
          <Caption bold>
            {intl.formatMessage({ id: "metrostav.implement_section.caption" })}
          </Caption>
        </CaptionWrapper>
        <Spacer size="medium" />
        <Button
          externalRoute="https://www.metrostav.cz/cs/"
          variant="light"
          justifyContent="center"
          text={intl.formatMessage({
            id: "metrostav.implement_section.button"
          })}
        />
      </Container>
      <Spacer size="extraLarge" />
      <BackgroundBlock>
        <Image withMaxWidth={false} src={MetrostavBackgroundScreens} />
      </BackgroundBlock>
      <Spacer size="extraLarge" />
    </>
  );
};

export default ImplementSection;
