import { useIntl } from "gatsby-plugin-intl";
import React, { FC, ReactNode } from "react";

import {
  CemexEnter1,
  CemexEnter2,
  FourFinanceEnter1,
  FourFinanceEnter2,
  MetrostavBlock1,
  MetrostavBlock2,
  MoPlayEnter1,
  MoPlayEnter2
} from "../../assets/images/Images";
import { Caption } from "../../components/Caption";
import Container from "../../components/Container";
import { Block, GridWrapper, Image } from "../../components/GridComponents";
import Heading from "../../components/Heading";
import Paragraph from "../../components/Paragraph";
import Spacer from "../../components/Spacer";
import styled from "../../styling/styled";
import { rem, theme } from "../../styling/theme";
import { SuccessStoriesIdsT } from "../SuccessStories/data";

const EnterText = styled.p`
  text-align: center;
  margin: 0 auto;
  width: 100%;
  max-width: ${rem(900)};
`;

const CaptionWrapper = styled.span`
  ${({ theme }): string[] => [theme.media.maxM]} {
    margin-top: ${rem(50)};
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: ${rem(20)};
`;

export const FortuneLogo = styled.img`
  width: 100%;
  max-width: ${rem(70)};
`;

type EnterSectionT = {
  caseStudyId: SuccessStoriesIdsT;
  underParagraphPicture?: ReactNode;
};

type EnterSectionItemT = {
  paragraphBold: boolean;
  image1: string;
  image2: string;
};

type EnterSectionIds = "moplay" | "4finance" | "cemex" | "metrostav";

type CaseStudiesT = { [key in EnterSectionIds]: EnterSectionItemT };

const caseStudies: CaseStudiesT = {
  moplay: {
    paragraphBold: true,
    image1: MoPlayEnter1,
    image2: MoPlayEnter2
  },
  "4finance": {
    paragraphBold: false,
    image1: FourFinanceEnter1,
    image2: FourFinanceEnter2
  },
  cemex: {
    paragraphBold: true,
    image1: CemexEnter1,
    image2: CemexEnter2
  },
  metrostav: {
    paragraphBold: true,
    image1: MetrostavBlock1,
    image2: MetrostavBlock2
  }
};

const EnterSection: FC<EnterSectionT> = ({
  caseStudyId,
  underParagraphPicture
}) => {
  const intl = useIntl();
  const splitedString = intl
    .formatMessage({ id: `${caseStudyId}.enter_section.text_3` })
    .split("|");

  return (
    <Container>
      <Spacer size="extraLarge" />
      <GridWrapper>
        <EnterText>
          {intl.formatMessage({
            id: `${caseStudyId}.enter_section.paragraph`
          })}
        </EnterText>
        <ImageWrapper>
          {underParagraphPicture && underParagraphPicture}
        </ImageWrapper>
        <Spacer size="extraLarge" />
        <Block paddingRight={100}>
          <Heading
            symbolColor={theme.colors.darkText}
            title={intl.formatMessage({
              id: `${caseStudyId}.enter_section.title`
            })}
          />
          <Spacer size="extraSmall" />
          <Paragraph
            paragraph={intl.formatMessage({
              id: `${caseStudyId}.enter_section.text_1`
            })}
            color={theme.colors.darkText}
          />
          <Paragraph
            paragraph={intl.formatMessage({
              id: `${caseStudyId}.enter_section.text_2`
            })}
            color={theme.colors.darkText}
            bold={caseStudies[caseStudyId].paragraphBold}
          />
        </Block>
        <Block>
          <Image
            alt={intl.formatMessage({
              id: `${caseStudyId}.enter_section.title`
            })}
            src={caseStudies[caseStudyId].image1}
          />
        </Block>
        <Spacer size="large" />
        <Block width={5} paddingRight={50}>
          <Image
            alt={intl.formatMessage({
              id: `${caseStudyId}.enter_section.title`
            })}
            src={caseStudies[caseStudyId].image2}
          />
        </Block>
        <Block width={7} paddingRight={30}>
          <Spacer size="medium" />
          <CaptionWrapper>
            <Caption bold>{splitedString[0]}</Caption>
            <Caption>{splitedString[1]}</Caption>
          </CaptionWrapper>
        </Block>
      </GridWrapper>
      <Spacer mobileSize="medium" size="extraLarge" />
    </Container>
  );
};

export default EnterSection;
