import styled from "../styling/styled";
import { rem } from "../styling/theme";

type CaptionStyleT = {
  bold?: boolean;
};

export const Caption = styled.span<CaptionStyleT>`
  font-size: ${rem(30)};
  text-transform: uppercase;
  line-height: ${rem(40)};
  font-weight: ${({ bold }): number => (bold ? 700 : 400)};
  ${({ theme }): string[] => [theme.media.maxL]} {
    margin: ${rem(20)} 0;
  }
  ${({ theme }): string[] => [theme.media.maxXs]} {
    font-size: ${rem(15)};
    line-height: 1.5;
  }
`;

export const CaptionWrapper = styled.div`
  width: 100%;
  text-align: center;
  max-width: ${rem(700)};
  margin: 0 auto;
`;
