import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import { MetrostavConcrete } from "../../assets/images/Images";
import Container from "../../components/Container";
import { Block, GridWrapper, Image } from "../../components/GridComponents";
import InnerHTML from "../../components/InnerHTML";
import Paragraph from "../../components/Paragraph";
import Spacer from "../../components/Spacer";
import styled from "../../styling/styled";
import { rem, theme } from "../../styling/theme";
import { putEmptyLineInsteadSpace } from "../../utils/putEmptyLineInsteadSpace";
import HeadingBlock, { PARAGRAPH_WIDTH } from "../general/HeadingBlock";

const List = styled.ul`
  margin: ${rem(40)} 0;
`;

const Item = styled.li`
  font-size: ${rem(14)};
  line-height: ${rem(30)};
`;

const ConcreteSection: FC = () => {
  const intl = useIntl();

  return (
    <Container>
      <Spacer size="extraLarge" />
      <GridWrapper>
        <Block justifyContent="flex-start">
          <HeadingBlock
            heading={putEmptyLineInsteadSpace(
              intl.formatMessage({ id: "metrostav.concrete_section.title" }),
              2
            )}
            pre={intl.formatMessage({ id: "metrostav.concrete_section.pre" })}
            paragraph={intl.formatMessage({
              id: "metrostav.concrete_section.text_1"
            })}
          >
            <List>
              {[...Array(5)].map((_, index) => (
                <Item key={index}>
                  <InnerHTML
                    content={intl.formatMessage({
                      id: `metrostav.concrete_section.list_item_${index + 1}`
                    })}
                  />
                </Item>
              ))}
            </List>
            <Paragraph
              maxWidth={PARAGRAPH_WIDTH}
              color={theme.colors.darkText}
              paragraph={intl.formatMessage({
                id: "metrostav.concrete_section.text_2"
              })}
            />
          </HeadingBlock>
          <Spacer size="medium" />
        </Block>
        <Block paddingLeft={50}>
          <Image src={MetrostavConcrete} alt="Metrostav" />
        </Block>
      </GridWrapper>
      <Spacer mobileSize="large" size="huge" />
    </Container>
  );
};

export default ConcreteSection;
