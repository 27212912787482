import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import {
  MetrostavPrototype,
  MetrostavScreen
} from "../../assets/images/Images";
import Container from "../../components/Container";
import { Block, GridWrapper, Image } from "../../components/GridComponents";
import Spacer from "../../components/Spacer";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";
import { putEmptyLineInsteadSpace } from "../../utils/putEmptyLineInsteadSpace";
import HeadingBlock from "../general/HeadingBlock";

const ImageWrapper = styled.div`
  position: relative;
  top: ${rem(-100)};
  ${({ theme }): string[] => [theme.media.maxL]} {
    top: 0;
  }
`;

const PrototypeSection: FC = () => {
  const intl = useIntl();

  return (
    <Container>
      <Spacer size="extraLarge" />
      <GridWrapper>
        <Block width={5} justifyContent="flex-start">
          <HeadingBlock
            heading={putEmptyLineInsteadSpace(
              intl.formatMessage({ id: "metrostav.prototype_section.title" }),
              2
            )}
            pre={intl.formatMessage({ id: "metrostav.prototype_section.pre" })}
            paragraph={intl.formatMessage({
              id: "metrostav.prototype_section.paragraph"
            })}
          />
          <Spacer size="medium" />
        </Block>
        <Block width={7} paddingLeft={50}>
          <ImageWrapper>
            <Image src={MetrostavPrototype} alt="Metrostav" />
          </ImageWrapper>
        </Block>
        <Spacer size="large" />
        <Block width={12} paddingLeft={100} paddingRight={200}>
          <Image withMaxWidth={false} src={MetrostavScreen} alt="Metrostav" />
        </Block>
      </GridWrapper>
    </Container>
  );
};

export default PrototypeSection;
