import React, { FC } from "react";

import Layout from "../../components/Layout";
import EnterSection from "../../partials/general/EnterSection";
import Introduction from "../../partials/general/Introduction";
import SuccessStoriesFooter from "../../partials/general/SuccessStoriesFooter";
import ConcreteSection from "../../partials/Metrostav/ConcreteSection";
import FreshSection from "../../partials/Metrostav/FreshSection";
import ImplementSection from "../../partials/Metrostav/ImplementSection";
import PrototypeSection from "../../partials/Metrostav/PrototypeSection";

const Metrostav: FC = () => {
  const ID = "metrostav";

  return (
    <Layout
      helmetKey={ID}
      emptyMenu={true}
      blackMenu={true}
      lightColorVariant
      menuIconType="/success-stories"
      render={(): React.ReactChild => (
        <>
          <Introduction caseStudyId={ID} />
          <EnterSection caseStudyId={ID} />
          <ConcreteSection />
          <PrototypeSection />
          <FreshSection />
          <ImplementSection />
          <SuccessStoriesFooter />
        </>
      )}
    />
  );
};

export default Metrostav;
