import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import {
  MetrostavUIScreen,
  MetrostavUIScreenMobile
} from "../../assets/images/Images";
import Container from "../../components/Container";
import { Block, GridWrapper } from "../../components/GridComponents";
import Spacer from "../../components/Spacer";
import { useResize } from "../../hooks/useResize";
import styled from "../../styling/styled";
import { breakpoints, rem } from "../../styling/theme";
import { putEmptyLineInsteadSpace } from "../../utils/putEmptyLineInsteadSpace";
import HeadingBlock from "../general/HeadingBlock";

const Image = styled.img`
  position: absolute;
  max-width: ${rem(1000)};
  z-index: 10;
  ${({ theme }): string[] => [theme.media.maxLg]} {
    left: ${rem(-200)};
    max-width: ${rem(900)};
  }
  ${({ theme }): string[] => [theme.media.maxL]} {
    position: relative;
    left: auto;
    max-width: ${rem(400)};
    width: 100%;
  }
`;

const FreshSection: FC = () => {
  const intl = useIntl();
  const { windowWidth, mobileVersion } = useResize();
  return (
    <Container>
      <Spacer mobileSize="extraLarge" size="huge" />
      <GridWrapper>
        <Block width={5} paddingRight={50} justifyContent="flex-start">
          <Image
            src={mobileVersion ? MetrostavUIScreenMobile : MetrostavUIScreen}
            alt={intl.formatMessage({
              id: "metrostav.fresh_section.title"
            })}
          />
        </Block>
        <Block width={7} paddingLeft={windowWidth < breakpoints.xl ? 0 : 200}>
          <Spacer mobileSize="medium" size="extraLarge" />
          <Spacer mobileSize="medium" size="large" />
          <HeadingBlock
            withoutBlock={mobileVersion}
            heading={putEmptyLineInsteadSpace(
              intl.formatMessage({
                id: "metrostav.fresh_section.title"
              }),
              2
            )}
            pre={intl.formatMessage({
              id: "metrostav.fresh_section.pre"
            })}
            paragraph={intl.formatMessage({
              id: "metrostav.fresh_section.paragraph"
            })}
          />
        </Block>
      </GridWrapper>
      <Spacer mobileSize="medium" size="huge" />
      <Spacer mobileSize="medium" size="huge" />
    </Container>
  );
};

export default FreshSection;
